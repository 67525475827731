import React from 'react';
import s from './ColorFilter.scss';

import {IFilterOption} from '../../../types/galleryTypes';
import {
  ColorSampleGroup,
  IColorSampleGroupOption,
} from '@wix/wixstores-client-common-components/dist/es/src/ColorSampleGroup/ColorSampleGroup';
import autobind from 'autobind-decorator';
import _ from 'lodash';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';

export interface IColorFilterProps extends IGalleryGlobalProps {
  colorOptions: IFilterOption[];
  showOptionName?: Function;
  filterId: number;
  selected: string[];
}

@withGlobals
export class ColorFilter extends React.Component<IColorFilterProps> {
  public shouldComponentUpdate(nextProps) {
    return !_.isEqual(this.props.selected, nextProps.selected);
  }

  public render() {
    const {colorOptions, selected: controllerSelected} = this.props;
    const {isMobile} = this.props.globals;
    const options: IColorSampleGroupOption[] = colorOptions.map((option, i) => ({
      id: i,
      value: option.key,
      description: option.value,
    }));

    const selected = options.filter(option => controllerSelected.indexOf(option.value) > -1);
    return (
      <div className={s.colorList}>
        <ColorSampleGroup
          selected={selected as any}
          options={options}
          onMouseLeave={() => this.props.showOptionName('')}
          onMouseEnter={value => this.props.showOptionName(value)}
          onSelect={this.handleClick}
          allowMultiple={true}
          shouldFocus={!isMobile}
        />
      </div>
    );
  }

  @autobind
  private handleClick(selections: IColorSampleGroupOption[]) {
    const selectedValue = _.xor(selections.map(option => option.value), this.props.selected);
    this.props.globals.filterProducts(this.props.filterId, selectedValue[0]);
  }
}
