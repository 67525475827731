import * as React from 'react';
import InputRange, {InputRangeProps} from 'react-input-range';
import {Omit} from '@wix/wixstores-client-core/dist/es/src/types/common';
import styles from './PriceSlider.scss';
import classNames from 'classnames';

export interface IPriceSliderProps extends Omit<InputRangeProps, 'classNames'> {}

export class PriceSlider extends React.PureComponent<IPriceSliderProps> {
  public render(): React.ReactNode {
    return (
      <div className={styles.sliderWrapper}>
        <InputRange
          {...this.props}
          classNames={{
            inputRange: styles.priceSlider,
            slider: styles.slider,
            valueLabel: classNames(styles.valueLabel, styles.label),
            track: styles.track,
            activeTrack: classNames(styles.track, styles.activeTrack),
            minLabel: classNames(styles.minLabel, styles.label),
            maxLabel: classNames(styles.maxLabel, styles.label),
            disabledInputRange: styles.isDisabled,
            labelContainer: styles.labelContainer,
            sliderContainer: styles.sliderContainer,
          }}
        />
      </div>
    );
  }
}
