import {IGalleryStyleParams, IPropsInjectedByViewerScript, IGallerySantaProps} from '../types/galleryTypes';

type ProvidedGlobalPropNames =
  | 'appLoadBI'
  | 'dimensions'
  | 'experiments'
  | 'filterModels'
  | 'filterProducts'
  | 'filterProductsOnMobile'
  | 'focusedProductIndex'
  | 'getCategoryProducts'
  | 'handleProductItemClick'
  | 'hasMoreProductsToLoad'
  | 'hasSelectedFilters'
  | 'isFirstPage'
  | 'isInteractive'
  | 'isLiveSiteMode'
  | 'isMobile'
  | 'isRTL'
  | 'loadMoreProducts'
  | 'loading'
  | 'mainCollectionId'
  | 'openQuickView'
  | 'productIdToProductPageUrlMap'
  | 'products'
  | 'setProductsPerPage'
  | 'sortProducts'
  | 'textsMap'
  | 'toggleFiltersModalVisibility'
  | 'totalProducts'
  | 'updateLayout'
  | 'handleMobileSortClick'
  | 'selectedSort';

export interface IGalleryGlobalProps {
  globals?: Pick<IPropsInjectedByViewerScript & IGallerySantaProps, ProvidedGlobalPropNames> & {
    styleParams: IGalleryStyleParams;
  };
}

export const galleryGlobalsStrategy = (props: IPropsInjectedByViewerScript & IGallerySantaProps) => {
  const {
    experiments,
    filterModels,
    filterProducts,
    filterProductsOnMobile,
    focusedProductIndex,
    getCategoryProducts,
    handleProductItemClick,
    hasMoreProductsToLoad,
    hasSelectedFilters,
    isFirstPage,
    isInteractive,
    isLiveSiteMode,
    isMobile,
    isRTL,
    loadMoreProducts,
    loading,
    mainCollectionId,
    openQuickView,
    productIdToProductPageUrlMap,
    products,
    setProductsPerPage,
    sortProducts,
    style,
    textsMap,
    toggleFiltersModalVisibility,
    totalProducts,
    handleMobileSortClick,
    selectedSort,
    ...locals
  } = props;

  const {appLoadBI, dimensions, updateLayout} = props.host;
  return {
    globals: {
      appLoadBI,
      dimensions,
      experiments,
      filterModels,
      filterProducts,
      filterProductsOnMobile,
      focusedProductIndex,
      getCategoryProducts,
      handleProductItemClick,
      hasMoreProductsToLoad,
      hasSelectedFilters,
      isFirstPage,
      isInteractive,
      isLiveSiteMode,
      isMobile,
      isRTL,
      loadMoreProducts,
      loading,
      mainCollectionId,
      openQuickView,
      productIdToProductPageUrlMap,
      products,
      setProductsPerPage,
      sortProducts,
      styleParams: style.styleParams,
      textsMap,
      toggleFiltersModalVisibility,
      totalProducts,
      updateLayout,
      handleMobileSortClick,
      selectedSort,
    },
    locals,
  };
};
