import React from 'react';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {withDefaultStyleParams} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/defaultStyleParams/withDefaultStyleParams';
import {getStyleParamsWithDefaultsFunc} from './getStyleParamsWithDefaultsFunc';
import {withGlobalsProvider, initContext, Strategy} from './globalPropsContext';

export interface IGetWrappedComponentOptions {
  entryName: string;
  Component: React.ComponentType;
  globalPropsStrategy: Strategy<any>;
}

export function getWrappedComponent({entryName, Component, globalPropsStrategy}: IGetWrappedComponentOptions) {
  initContext(globalPropsStrategy);
  let wrappedComponent;
  /* istanbul ignore next: minified css tested in e2e */
  const cssPath = [`${entryName}${!process.env.IS_MINIFIED ? '' : '.min'}.css`, `${entryName}.stylable.bundle.css`];
  wrappedComponent = withStyles(withGlobalsProvider(Component), {
    cssPath,
  });
  wrappedComponent = withDefaultStyleParams(wrappedComponent, ({host}) => getStyleParamsWithDefaultsFunc(host));

  return wrappedComponent;
}
