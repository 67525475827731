import React from 'react';
import s from './FiltersSubmitButton.scss';
import {Loading} from '../../Loading/Loading';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';

export interface IFiltersSubmitButton extends IGalleryGlobalProps, IProvidedTranslationProps {
  closeMobileFiltersModal: Function;
}

@withGlobals
@withTranslations()
export class FiltersSubmitButton extends React.Component<IFiltersSubmitButton> {
  private handleClick() {
    this.props.globals.filterProductsOnMobile();
  }

  public componentWillUnmount() {
    this.props.closeMobileFiltersModal && this.props.closeMobileFiltersModal();
  }

  public render() {
    const {
      globals: {loading},
    } = this.props;

    return (
      <button className={s.filtersSubmitButton} data-hook="filters-submit-button" onClick={() => this.handleClick()}>
        {loading ? <Loading /> : this.props.t('filtersSubmitButtonText')}
      </button>
    );
  }
}
