import React from 'react';

import s from './FiltersHeader.scss';
import {X} from '../../../icons/dist';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';

export interface IFiltersHeaderProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  closeMobileFiltersModal: Function;
}

@withGlobals
@withTranslations()
export class FiltersHeader extends React.Component<IFiltersHeaderProps> {
  public render() {
    const {isMobile} = this.props.globals;

    return (
      <>
        <span className={s.header}>
          <h2 data-hook="filters-title" className={s.title}>
            {this.props.t('filtersTitleText')}
          </h2>
          {isMobile && (
            <span data-hook="cancel" className={s.cancel} onClick={() => this.props.closeMobileFiltersModal()}>
              <X />
            </span>
          )}
        </span>
      </>
    );
  }
}
