import React from 'react';
import s from './CheckboxList.scss';
import classNames from 'classnames';

export interface ICheckboxListProps {
  options: {id: string; name: string}[];
  selected: string[];
  onChange: Function;
  id: string;
}

export class CheckboxList extends React.Component<ICheckboxListProps> {
  constructor(props) {
    super(props);
  }

  public render() {
    const {options, selected, onChange} = this.props;

    return (
      <ul className={s.options}>
        {options.map(option => (
          <li key={option.id}>
            <input
              checked={selected.indexOf(option.id) !== -1}
              className={s.checkbox}
              data-hook="input"
              type="checkbox"
              id={`${this.props.id}${option.id}`}
              value={option.id}
              onChange={e => onChange(e)}
            />
            <label
              className={classNames('wixSdkShowFocusOnSibling', s.label)}
              data-hook="label"
              htmlFor={`${this.props.id}${option.id}`}>
              <span className={s.labelText}>{option.name}</span>
            </label>
          </li>
        ))}
      </ul>
    );
  }
}
