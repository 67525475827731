import * as React from 'react';

let Provider;
let Consumer;
let strategy: (props: any) => {globals: any; locals: any};

export type Strategy<T> = (props: any) => {globals: T; locals: any};

export function withGlobalsProvider(Component) {
  return props => {
    const {globals, locals} = strategy(props);
    return (
      <Provider value={globals}>
        <Component {...locals} />
      </Provider>
    );
  };
}

export const withGlobals = (Component): any => {
  return props => {
    return <Consumer>{globals => <Component globals={globals} {...props} ref={props.innerRef} />}</Consumer>;
  };
};

export function initContext<T>(aStrategy: Strategy<T>) {
  const ctx = React.createContext<T>(undefined);
  strategy = aStrategy;
  Provider = ctx.Provider;
  Consumer = ctx.Consumer;
}
